import React from 'react';

import {Link} from 'gatsby';
import backButton from '../images/icons/back-button.svg';
import './placeholderColor.css';
import ForgotPassword from '../components/ForgotPassword';
import './signin.css';

export default () => (
	<div className="fullScreen">
		<div className="topBar">
			<div className="logoDiv">
				<Link to="/">
					<span className="logo">Auto</span>
					<span className="logo text-style-1">Save</span>
				</Link>
			</div>
		</div>
		<div className="signInScreen">
			<div className="headGroup otpHeader">
				<Link to="/signin">
						<img className="backButton" src={backButton} alt="back button" />
				</Link>
				<p className="headerWithBack normalHeaderText otpText">
					Enter your email id to reset your password
				</p>
			</div>
			<ForgotPassword />
		</div>
	</div>
);
